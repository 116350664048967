export default function Logo(props) {
    return (
        <svg
            viewBox="0 0 168 54"
            fill="none"
            {...props}
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_1_1661)" filter="url(#filter0_d_1_1661)">
                <path
                    d="M23.3001 8.84082L23.1726 12.9511C20.7326 12.7601 18.4351 12.6633 16.2751 12.6633C16.2501 15.0719 16.2376 17.7534 16.2376 20.7102C16.2376 25.2967 16.3051 29.6377 16.4376 33.7281H11.3176C11.4751 29.6749 11.5551 25.3364 11.5551 20.7102C11.5551 17.8055 11.5301 15.1215 11.4826 12.6633C9.76259 12.6633 7.40259 12.765 4.40259 12.9684L4.55009 8.84082H23.3001Z"
                    fill="#EF4130"
                />
                <path
                    d="M39.7225 33.728H34.8925C35.05 31.4732 35.13 28.9431 35.13 26.1401C35.13 25.0065 35.1975 23.5975 35.33 21.9157H35.055C34.7375 22.8707 33.2875 24.9221 30.7 28.0699C28.31 30.9746 27.115 32.8623 27.115 33.728H23.3325C23.49 30.9498 23.57 27.9236 23.57 24.6493C23.57 20.9657 23.3625 17.9964 22.9475 15.7416L28.1425 15.1885C27.9975 17.6343 27.9225 20.0702 27.9225 22.4912C27.9225 23.7662 27.855 25.4033 27.7225 27.4027H27.9975C28.23 26.599 29.6125 24.6121 32.15 21.4394C34.54 18.4454 35.735 16.5453 35.735 15.7441L39.705 15.191C39.5575 17.6368 39.485 20.792 39.485 24.6517C39.485 27.7078 39.565 30.734 39.7225 33.728Z"
                    fill="#EF4130"
                />
                <path
                    d="M60.4827 24.5922C60.4827 27.7772 59.5227 30.2205 57.6027 31.9222C55.6827 33.6238 53.3727 34.4746 50.6777 34.4746C47.4327 34.4746 45.1702 33.4452 43.8902 31.3889C42.6102 29.33 41.9702 26.0011 41.9702 21.4022C41.9702 18.9936 42.2077 16.9422 42.6827 15.248C43.1577 13.5538 43.8927 12.1523 44.8877 11.0435C45.8827 9.93466 47.1402 9.08383 48.6652 8.49098C50.1902 7.89813 52.0002 7.49381 54.0977 7.278C54.7552 7.2135 55.4727 7.11676 56.2477 6.98281C57.0227 6.84887 57.9227 6.71244 58.9452 6.57104L58.7252 10.8525C58.4077 10.8897 57.8102 10.9666 56.9327 11.0807C56.0552 11.1948 54.9502 11.3411 53.6227 11.5197C52.4877 11.6735 51.4727 11.8744 50.5777 12.1225C49.6802 12.3705 48.9177 12.7426 48.2902 13.2412C47.6627 13.7373 47.1752 14.4021 46.8277 15.2281C46.4802 16.0566 46.2827 17.1332 46.2327 18.4578H46.4152C47.8052 16.776 49.6827 15.9351 52.0502 15.9351C54.5027 15.9351 56.5202 16.6371 58.1052 18.0386C59.6902 19.4376 60.4827 21.623 60.4827 24.5922ZM55.7452 25.1825C55.7452 23.5255 55.3527 22.1588 54.5652 21.0822C53.7777 20.0057 52.6477 19.4674 51.1727 19.4674C49.0877 19.4674 47.4702 20.219 46.3252 21.7222C46.3377 25.1379 46.7202 27.5291 47.4777 28.9009C48.2327 30.2701 49.3977 30.9548 50.9727 30.9548C52.4252 30.9548 53.5827 30.4066 54.4477 29.3102C55.3127 28.2162 55.7452 26.8395 55.7452 25.1825Z"
                    fill="#EF4130"
                />
                <path
                    d="M78.5351 22.6995C78.5351 23.3494 78.4976 24.1978 78.4251 25.2421L66.6451 25.4529C66.8151 26.8941 67.4076 28.0897 68.4201 29.0472C69.4326 30.0022 70.7626 30.481 72.4076 30.481C74.2376 30.481 76.1826 30.1932 78.2426 29.6202L77.8776 33.3286C76.1951 33.9661 74.1101 34.2836 71.6201 34.2836C68.4726 34.2836 66.0626 33.3609 64.3851 31.5129C62.7076 29.6649 61.8701 27.4423 61.8701 24.8427C61.8701 22.129 62.6651 19.7749 64.2576 17.7806C65.8501 15.7862 68.0601 14.7891 70.8901 14.7891C73.4751 14.7891 75.3951 15.5208 76.6526 16.9868C77.9051 18.4503 78.5351 20.3554 78.5351 22.6995ZM74.2551 22.5259C74.2551 19.5963 73.0601 18.1303 70.6701 18.1303C68.3901 18.1303 67.0351 19.646 66.6101 22.6797L74.2551 22.5259Z"
                    fill="#EF4130"
                />
                <path
                    d="M95.25 15.5679L95.14 19.4474C93.3725 19.2564 91.615 19.1597 89.8725 19.1597C89.835 20.9556 89.8175 22.8235 89.8175 24.7608C89.8175 28.0351 89.9025 31.0241 90.0725 33.7254H85.1525C85.3225 31.163 85.4075 28.1765 85.4075 24.7608C85.4075 22.8879 85.3775 21.0201 85.315 19.1597C83.5475 19.1597 81.705 19.2564 79.79 19.4474L79.9175 15.5679H95.25Z"
                    fill="#EF4130"
                />
                <path
                    d="M111.108 29.7146L110.798 33.3659C109.688 33.9786 108.183 34.2838 106.28 34.2838C103.133 34.2838 100.715 33.3659 99.0276 31.5303C97.3376 29.6947 96.4951 27.492 96.4951 24.9172C96.4951 22.2407 97.3801 19.9065 99.1476 17.9122C100.915 15.9178 103.338 14.9207 106.41 14.9207C107.91 14.9207 109.245 15.0993 110.418 15.4565L109.795 19.3559C108.673 18.8722 107.418 18.6291 106.028 18.6291C104.553 18.6291 103.388 19.1748 102.533 20.2637C101.678 21.3527 101.253 22.6947 101.253 24.2872C101.253 26.0211 101.758 27.4871 102.77 28.6827C103.783 29.8808 105.215 30.4786 107.07 30.4786C108.15 30.4786 109.498 30.2231 111.108 29.7146Z"
                    fill="#EF4130"
                />
                <path
                    d="M129.233 33.728H124.33C124.233 33.5494 124.085 33.2046 123.89 32.6961C123.183 30.1337 122.455 28.3924 121.705 27.4672C120.955 26.5444 120.013 26.0805 118.88 26.0805C118.588 26.0805 118.185 26.0929 117.673 26.1177C117.673 28.5636 117.765 31.1012 117.948 33.7256H113.045C113.228 31.178 113.32 28.2659 113.32 24.9891C113.32 21.3055 113.1 18.2172 112.663 15.7193L117.913 15.2034C117.755 17.3193 117.675 19.9065 117.675 22.965C118.2 22.9898 118.603 23.0022 118.883 23.0022C121.04 23.0022 122.62 20.8416 123.62 16.523C123.693 16.2055 123.828 15.8855 124.023 15.568H128.615C128.493 15.8607 128.28 16.4039 127.975 17.1928C126.585 21.1815 125.023 23.5206 123.293 24.2077V24.3987C124.45 24.7559 125.418 25.5001 126.193 26.6362C126.968 27.7698 127.713 29.4342 128.433 31.6245C128.648 32.3017 128.915 33.0012 129.233 33.728Z"
                    fill="#EF4130"
                />
                <path
                    d="M146.095 33.7081L141.778 33.8619C141.583 33.3261 141.485 32.8424 141.485 32.4083H141.248C139.905 33.6064 138.095 34.2042 135.815 34.2042C134.29 34.2042 133.025 33.7602 132.02 32.8746C131.013 31.9891 130.51 30.8133 130.51 29.3473C130.51 27.41 131.293 25.8249 132.86 24.5871C134.428 23.3518 137.175 22.6945 141.1 22.6176C141.125 22.3894 141.138 22.1711 141.138 21.9677C141.138 20.591 140.82 19.6831 140.188 19.244C139.553 18.805 138.388 18.5842 136.693 18.5842C134.85 18.5842 133.053 18.8397 131.295 19.3482L131.698 15.8308C133.355 15.233 135.698 14.9329 138.723 14.9329C140.93 14.9329 142.635 15.4166 143.835 16.3865C145.035 17.3539 145.638 19.0506 145.638 21.4716C145.638 22.0074 145.615 22.9822 145.573 24.3961C145.53 25.81 145.508 26.7526 145.508 27.2239C145.51 29.8657 145.705 32.0263 146.095 33.7081ZM141.213 29.7318C141.14 28.0103 141.102 26.5269 141.102 25.2792C136.943 25.2792 134.865 26.3806 134.865 28.5858C134.865 30.1014 135.78 30.8604 137.61 30.8604C139.048 30.8604 140.248 30.4858 141.213 29.7318Z"
                    fill="#EF4130"
                />
                <path
                    d="M164.295 33.7281H159.448C159.57 31.4336 159.63 29.0647 159.63 26.6164C158.863 26.5395 158.118 26.5023 157.398 26.5023C156.093 26.5023 155.158 26.8644 154.59 27.5912C154.023 28.318 153.228 30.362 152.203 33.7281H147.593C148.58 30.6324 149.413 28.5264 150.09 27.4102C150.768 26.2964 151.575 25.6291 152.515 25.4133V25.1256C149.858 24.4757 148.528 22.9477 148.528 20.5391C148.528 18.768 149.125 17.4533 150.32 16.5925C151.515 15.7318 153.083 15.3026 155.023 15.3026C156.595 15.3026 158.615 15.3795 161.078 15.5309L164.298 15.1687C164.14 18.0238 164.06 21.1766 164.06 24.6295C164.055 28.4644 164.135 31.4981 164.295 33.7281ZM159.63 23.4438C159.63 21.6082 159.593 20.0033 159.52 18.6266C158.178 18.5125 156.868 18.4554 155.588 18.4554C153.855 18.4554 152.99 19.2393 152.99 20.807C152.99 22.6153 154.278 23.5207 156.85 23.5207C157.533 23.5207 158.458 23.4959 159.63 23.4438Z"
                    fill="#EF4130"
                />
                <path
                    d="M58.2801 46.8847L58.4801 46.6342C58.7851 46.2447 59.0401 45.8925 59.2451 45.5774C59.4501 45.2624 59.7076 44.8382 60.0176 44.3074C59.9526 44.2429 59.9201 44.1759 59.9201 44.109C59.9201 43.9552 59.9651 43.7642 60.0526 43.5384C60.1401 43.3127 60.2351 43.154 60.3326 43.0647C60.3976 42.9878 60.5601 42.9555 60.8226 42.9679L61.2951 42.9927C61.4626 43.0275 61.5476 43.1093 61.5476 43.2433C61.5476 43.4367 61.3701 43.7691 61.0176 44.2429C60.9926 44.2776 60.9401 44.3521 60.8626 44.4662C61.1201 44.7787 61.3026 45.0466 61.4051 45.2699C61.5076 45.4931 61.5601 45.7288 61.5601 45.9719C61.5601 46.0909 61.5451 46.2522 61.5176 46.4556C61.4901 46.659 61.4126 46.9517 61.2876 47.3337C61.1626 47.7157 60.9976 48.0629 60.7901 48.3755C61.0101 48.3755 61.2476 48.3085 61.5001 48.1771C61.7551 48.0456 62.1651 47.738 62.7326 47.2593C62.8176 47.1824 62.9276 47.0931 63.0601 46.9938C63.1926 46.8946 63.2751 46.8276 63.3076 46.7979V47.8868L63.1076 48.0481C62.1601 48.7997 61.4101 49.1767 60.8601 49.1767C60.6876 49.1767 60.4476 49.1147 60.1426 48.9907C59.9826 49.0924 59.8251 49.1618 59.6651 49.199C59.5051 49.2362 59.2851 49.2536 59.0051 49.2536C58.8076 49.2536 58.6726 49.2263 58.5976 49.1693C58.5251 49.1147 58.4876 49.0105 58.4876 48.8617C58.4876 48.5466 58.5401 48.2986 58.6501 48.125C58.6926 48.0654 58.7526 48.0357 58.8301 48.0357C58.9426 48.0357 59.1226 48.058 59.3726 48.1002C59.5026 48.125 59.6076 48.1398 59.6901 48.1398C59.7976 48.1398 59.9226 48.0034 60.0626 47.7306C60.2026 47.4577 60.3201 47.1476 60.4151 46.8053C60.5101 46.463 60.5576 46.1852 60.5576 45.9743C60.5576 45.6494 60.4876 45.3815 60.3451 45.1731C60.3051 45.2326 60.2476 45.3244 60.1701 45.4485C59.7226 46.1529 59.3576 46.6862 59.0751 47.0434C58.7926 47.4031 58.5276 47.7008 58.2776 47.9414V46.8847H58.2801Z"
                    fill="#EF4130"
                />
                <path
                    d="M66.4826 46.8027V47.8916C66.4526 47.9214 66.3726 47.9884 66.2426 48.0901C65.7301 48.5142 65.2926 48.8194 64.9276 49.0104C64.5626 49.2014 64.2301 49.2956 63.9301 49.2956C63.7751 49.2956 63.6451 49.2584 63.5426 49.1815C63.4476 49.0997 63.3726 48.9087 63.3126 48.6085C63.2551 48.3084 63.2251 47.9636 63.2251 47.5791C63.2251 46.6315 63.4976 45.4756 64.0451 44.1113H65.1301L65.0001 44.5082C64.7276 45.3193 64.5401 45.9568 64.4326 46.4157C64.3251 46.8746 64.2701 47.2889 64.2701 47.656C64.2701 47.8817 64.2876 48.0429 64.3251 48.1372C64.3626 48.2315 64.4226 48.2786 64.5101 48.2786C64.6101 48.2786 64.7376 48.234 64.8976 48.1397C65.0576 48.0479 65.2501 47.904 65.4751 47.7106C65.7001 47.5171 65.8701 47.3682 65.9801 47.269C66.0926 47.1648 66.2601 47.011 66.4826 46.8027ZM65.2701 41.3281C65.6926 41.3281 65.9026 41.4522 65.9026 41.7002C65.9026 41.8366 65.8676 42.0078 65.8001 42.2087C65.7301 42.4121 65.6551 42.561 65.5751 42.6552C65.4751 42.7544 65.3001 42.8016 65.0451 42.8016C64.8676 42.8016 64.7426 42.7768 64.6676 42.7247C64.5926 42.6726 64.5551 42.5882 64.5551 42.4692C64.5551 42.4295 64.5601 42.3749 64.5701 42.3005C64.5801 42.2261 64.6026 42.1318 64.6351 42.0152C64.6676 41.8987 64.7076 41.792 64.7576 41.6952C64.8076 41.596 64.8776 41.5117 64.9676 41.4397C65.0551 41.3653 65.1576 41.3281 65.2701 41.3281Z"
                    fill="#EF4130"
                />
                <path
                    d="M67.2076 44.1089H68.305C68.095 44.672 67.9126 45.2251 67.7626 45.7684C68.4376 45.1358 68.9576 44.7017 69.3226 44.4636C69.6876 44.2255 70.0126 44.1089 70.3001 44.1089C70.5251 44.1089 70.7125 44.223 70.865 44.4487C71.0175 44.6744 71.0951 44.9523 71.0951 45.2822C71.0951 45.6121 70.9775 46.1132 70.74 46.7879C70.545 47.3485 70.45 47.7503 70.45 47.9984C70.45 48.2415 70.52 48.363 70.6625 48.363C70.7225 48.363 70.8501 48.3084 71.0426 48.1993C71.2351 48.0902 71.49 47.9091 71.805 47.6536C72.12 47.4006 72.4126 47.1128 72.6826 46.7978V47.8867L72.4176 48.1249C71.9276 48.5565 71.525 48.8566 71.21 49.0253C70.895 49.194 70.5875 49.2783 70.2875 49.2783C70.015 49.2783 69.7976 49.1692 69.6351 48.9509C69.4726 48.7326 69.39 48.4399 69.39 48.0728C69.39 47.7429 69.5076 47.2443 69.7451 46.5721C69.9226 46.0561 70.0101 45.7064 70.0101 45.5278C70.0101 45.4087 69.9926 45.3119 69.9551 45.24C69.9176 45.1681 69.8726 45.1309 69.8151 45.1309C69.6651 45.1309 69.4325 45.2574 69.1175 45.5104C68.8025 45.7634 68.32 46.2124 67.67 46.8573L67.535 46.9863C67.51 47.2393 67.4976 47.5494 67.4976 47.9165C67.4976 48.0703 67.505 48.4077 67.5225 48.9286L67.535 49.2287H66.495C66.4825 49.0824 66.4676 48.8393 66.4501 48.4994L66.425 47.8148V47.3906C66.425 46.6093 66.6851 45.5154 67.2076 44.1089Z"
                    fill="#EF4130"
                />
                <path
                    d="M77.51 46.8027V47.8916C76.985 48.3877 76.485 48.7449 76.015 48.9657C75.5425 49.1865 75.04 49.2956 74.5075 49.2956C74.19 49.2956 73.91 49.2485 73.6675 49.1542C73.4275 49.06 73.24 48.9459 73.11 48.8119C72.9775 48.678 72.8725 48.5192 72.7925 48.3381C72.7125 48.157 72.66 47.9958 72.6375 47.8569C72.6125 47.718 72.6025 47.5791 72.6025 47.4377C72.6025 46.882 72.735 46.3438 72.9975 45.8253C73.26 45.3069 73.605 44.8877 74.035 44.5727C74.4625 44.2576 74.9075 44.0989 75.37 44.0989C75.7275 44.0989 76.0075 44.1907 76.2125 44.3717C76.4175 44.5528 76.52 44.8034 76.52 45.1258C76.52 45.4111 76.4525 45.6864 76.32 45.9494C76.1875 46.2123 76.0075 46.4182 75.785 46.567C75.4925 46.4479 75.265 46.2966 75.1075 46.1131C75.3225 45.8129 75.4575 45.617 75.5075 45.5227C75.56 45.4284 75.585 45.3218 75.585 45.2027C75.585 44.9844 75.4675 44.8753 75.23 44.8753C75.005 44.8753 74.7725 45.0117 74.53 45.2846C74.2875 45.5574 74.0875 45.8923 73.93 46.2867C73.7725 46.6811 73.695 47.0507 73.695 47.393C73.695 47.718 73.7875 47.9784 73.975 48.1744C74.1625 48.3704 74.4075 48.4696 74.715 48.4696C75.545 48.4671 76.48 47.9115 77.51 46.8027Z"
                    fill="#EF4130"
                />
                <path
                    d="M82.4702 46.8027V47.8792C81.1602 48.8318 79.8952 49.308 78.6727 49.308C78.4402 49.308 78.2377 49.2535 78.0652 49.1443C77.8927 49.0352 77.7627 48.8888 77.6702 48.7053C77.5802 48.5217 77.5152 48.3282 77.4727 48.1273C77.4302 47.9264 77.4102 47.713 77.4102 47.4873C77.4102 46.8796 77.5402 46.3016 77.7977 45.7534C78.0552 45.2052 78.4002 44.7637 78.8302 44.4288C79.2602 44.0964 79.7127 43.9277 80.1877 43.9277C80.5152 43.9277 80.7701 44.0121 80.9502 44.1808C81.1302 44.3494 81.2202 44.5876 81.2202 44.8951C81.2202 45.0787 81.1877 45.2548 81.1227 45.4235C81.0577 45.5922 80.9801 45.741 80.8877 45.8725C80.7952 46.0039 80.6702 46.1305 80.5152 46.257C80.3602 46.3835 80.2202 46.4877 80.0927 46.572C79.9652 46.6563 79.8077 46.7431 79.6177 46.8349C79.4277 46.9267 79.2802 46.9962 79.1727 47.0433C79.0652 47.0904 78.9277 47.145 78.7602 47.2095L78.4952 47.3112C78.5002 47.3955 78.5002 47.4873 78.4952 47.5865C78.4902 47.6858 78.5026 47.7875 78.5276 47.8916C78.5526 47.9958 78.5877 48.0926 78.6277 48.1769C78.6677 48.2637 78.7352 48.3307 78.8277 48.3853C78.9202 48.4398 79.0302 48.4646 79.1602 48.4646C79.6602 48.4646 80.1677 48.3357 80.6802 48.0777C81.2002 47.8222 81.7952 47.3955 82.4702 46.8027ZM78.6202 46.4753C79.1627 46.2867 79.5902 46.0635 79.9052 45.803C80.2202 45.5426 80.3777 45.2821 80.3777 45.0217C80.3777 44.8307 80.2802 44.7339 80.0877 44.7339C79.8002 44.7339 79.5102 44.9075 79.2202 45.2523C78.9277 45.5971 78.7277 46.0039 78.6202 46.4753Z"
                    fill="#EF4130"
                />
                <path
                    d="M88.0652 43.6673L87.4127 43.2431C87.6377 43.0818 87.8227 42.9429 87.9727 42.8264C88.1202 42.7098 88.3202 42.5411 88.5702 42.3178C88.8202 42.0946 89.0802 41.8267 89.3552 41.5141C89.6277 41.2016 89.9127 40.8469 90.2102 40.45C90.4977 40.4426 90.7427 40.4624 90.9477 40.5145C90.8052 40.9461 90.6427 41.4372 90.4627 41.9854C90.2827 42.5336 90.1577 42.9181 90.0877 43.1364C90.0177 43.3547 89.9377 43.6127 89.8452 43.9153C89.7527 44.2179 89.6652 44.5181 89.5827 44.8232L88.3427 49.2956H87.1802L87.9427 46.8027L88.3052 45.5475C88.4827 44.9199 88.7677 44.0145 89.1652 42.8288C88.7177 43.2084 88.3527 43.4887 88.0652 43.6673Z"
                    fill="#EF4130"
                />
                <path
                    d="M96.1051 45.2004C95.7526 45.4535 95.4601 45.6271 95.2301 45.7263C95.0001 45.8255 94.7626 45.8727 94.5151 45.8727C94.0626 45.8727 93.6926 45.704 93.4001 45.3642C93.1101 45.0243 92.9651 44.5902 92.9651 44.0594C92.9651 43.4492 93.1076 42.8638 93.3926 42.3106C93.6776 41.755 94.0526 41.3085 94.5201 40.9711C94.9876 40.6338 95.4801 40.4651 95.9951 40.4651C96.5551 40.4651 97.0001 40.6511 97.3301 41.0232C97.6601 41.3953 97.8251 41.8964 97.8251 42.5289C97.8251 42.9729 97.7576 43.4517 97.6226 43.9676C97.4876 44.4836 97.3026 44.9896 97.0676 45.4857C96.8326 45.9843 96.5476 46.463 96.2076 46.9244C95.8701 47.3858 95.5101 47.7926 95.1301 48.1424C94.7501 48.4921 94.3301 48.7724 93.8726 48.9808C93.4151 49.1891 92.9651 49.2958 92.5151 49.2958C92.3951 49.2958 92.3151 49.2636 92.2801 49.1966C92.2426 49.1296 92.2251 48.9808 92.2251 48.7451C92.2251 48.6856 92.2426 48.6434 92.2776 48.6211C92.3126 48.5988 92.3776 48.5789 92.4776 48.5665C92.8301 48.5244 93.2001 48.4003 93.5876 48.1944C93.9526 48.0034 94.3126 47.7231 94.6626 47.3535C95.0126 46.9864 95.3001 46.6243 95.5251 46.2696C95.7476 45.9148 95.9426 45.5576 96.1051 45.2004ZM95.9451 41.2142C95.6601 41.2142 95.3926 41.3258 95.1376 41.5466C94.8826 41.7699 94.6776 42.0377 94.5176 42.3553C94.3576 42.6703 94.2326 42.9903 94.1401 43.3103C94.0476 43.6303 94.0001 43.9031 94.0001 44.1313C94.0001 44.4141 94.0701 44.6349 94.2076 44.7936C94.3451 44.9549 94.5376 45.0342 94.7826 45.0342C94.9026 45.0342 95.0426 45.0144 95.2001 44.9722C95.3576 44.9325 95.5351 44.8507 95.7351 44.7316C95.9351 44.6126 96.1176 44.4612 96.2776 44.2752C96.4401 44.0916 96.5751 43.8386 96.6876 43.5186C96.8001 43.1986 96.8551 42.834 96.8551 42.4297C96.8551 42.0675 96.7701 41.7723 96.6026 41.5491C96.4351 41.3258 96.2151 41.2142 95.9451 41.2142Z"
                    fill="#EF4130"
                />
                <path
                    d="M102.333 45.2004C101.98 45.4535 101.688 45.6271 101.458 45.7263C101.228 45.8255 100.99 45.8727 100.743 45.8727C100.29 45.8727 99.9201 45.704 99.6276 45.3642C99.3376 45.0243 99.1926 44.5902 99.1926 44.0594C99.1926 43.4492 99.3351 42.8638 99.6201 42.3106C99.9051 41.755 100.28 41.3085 100.748 40.9711C101.215 40.6338 101.708 40.4651 102.223 40.4651C102.783 40.4651 103.228 40.6511 103.558 41.0232C103.888 41.3953 104.053 41.8964 104.053 42.5289C104.053 42.9729 103.985 43.4517 103.85 43.9676C103.715 44.4836 103.53 44.9896 103.295 45.4857C103.06 45.9843 102.775 46.463 102.435 46.9244C102.098 47.3858 101.738 47.7926 101.358 48.1424C100.978 48.4921 100.558 48.7724 100.1 48.9808C99.6426 49.1891 99.1926 49.2958 98.7426 49.2958C98.6226 49.2958 98.5426 49.2636 98.5076 49.1966C98.4701 49.1296 98.4526 48.9808 98.4526 48.7451C98.4526 48.6856 98.4701 48.6434 98.5051 48.6211C98.5401 48.5988 98.6051 48.5789 98.7051 48.5665C99.0576 48.5244 99.4276 48.4003 99.8151 48.1944C100.18 48.0034 100.54 47.7231 100.89 47.3535C101.24 46.9864 101.528 46.6243 101.753 46.2696C101.975 45.9148 102.17 45.5576 102.333 45.2004ZM102.17 41.2142C101.885 41.2142 101.618 41.3258 101.363 41.5466C101.108 41.7699 100.903 42.0377 100.743 42.3553C100.583 42.6703 100.458 42.9903 100.365 43.3103C100.273 43.6303 100.225 43.9031 100.225 44.1313C100.225 44.4141 100.295 44.6349 100.433 44.7936C100.57 44.9549 100.763 45.0342 101.008 45.0342C101.128 45.0342 101.268 45.0144 101.425 44.9722C101.583 44.9325 101.76 44.8507 101.96 44.7316C102.16 44.6126 102.343 44.4612 102.503 44.2752C102.665 44.0916 102.8 43.8386 102.913 43.5186C103.025 43.1986 103.08 42.834 103.08 42.4297C103.08 42.0675 102.995 41.7723 102.828 41.5491C102.663 41.3258 102.443 41.2142 102.17 41.2142Z"
                    fill="#EF4130"
                />
                <path
                    d="M106.64 44.481C106.98 44.2503 107.263 44.0915 107.488 44.0072C107.713 43.9229 107.948 43.8782 108.198 43.8782C108.628 43.8782 108.99 44.0494 109.283 44.3917C109.575 44.734 109.723 45.1606 109.723 45.6741C109.723 45.9867 109.675 46.3091 109.58 46.644C109.485 46.9789 109.348 47.3038 109.163 47.6189C108.98 47.9339 108.768 48.2142 108.528 48.4647C108.285 48.7153 108.008 48.9162 107.69 49.0675C107.373 49.2188 107.045 49.2957 106.705 49.2957C106.138 49.2957 105.688 49.1072 105.358 48.7277C105.028 48.3506 104.863 47.8347 104.863 47.1872C104.863 46.3414 105.093 45.4161 105.553 44.409C106.013 43.4019 106.603 42.5387 107.323 41.8169C107.733 41.3977 108.2 41.0628 108.73 40.8073C109.26 40.5543 109.745 40.4253 110.19 40.4253C110.285 40.4253 110.345 40.4476 110.375 40.4923C110.403 40.5369 110.418 40.6337 110.418 40.7775C110.418 40.9388 110.403 41.0479 110.373 41.1C110.343 41.1546 110.278 41.1868 110.18 41.1992C109.815 41.2339 109.435 41.3605 109.043 41.5837C108.65 41.807 108.293 42.0972 107.97 42.4544C107.733 42.7148 107.52 42.9927 107.328 43.2854C107.138 43.5781 106.908 43.9774 106.64 44.481ZM107.92 44.7191C107.748 44.7191 107.568 44.7514 107.38 44.8158C107.193 44.8803 107.008 44.982 106.823 45.121C106.638 45.2599 106.473 45.4236 106.325 45.6146C106.178 45.8056 106.06 46.0388 105.97 46.3166C105.88 46.5944 105.835 46.8945 105.835 47.2145C105.835 47.6089 105.923 47.924 106.098 48.1671C106.273 48.4077 106.5 48.5292 106.785 48.5292C107.06 48.5292 107.323 48.4201 107.573 48.2018C107.823 47.9835 108.025 47.7181 108.183 47.4031C108.34 47.088 108.463 46.773 108.555 46.4555C108.645 46.138 108.69 45.8651 108.69 45.6369C108.69 45.3516 108.623 45.1259 108.488 44.9647C108.35 44.801 108.16 44.7191 107.92 44.7191Z"
                    fill="#EF4130"
                />
            </g>
            <defs>
                <filter
                    id="filter0_d_1_1661"
                    x="0"
                    y="-4"
                    width="168"
                    height="57.3081"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_1_1661"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_1_1661"
                        result="shape"
                    />
                </filter>
                <clipPath id="clip0_1_1661">
                    <rect
                        width="160"
                        height="49.3082"
                        fill="white"
                        transform="translate(4)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
}
