import React, { useRef, useState, useEffect } from "react";
import api from "../api";
import Footer from "../components/Footer";
import { useParams } from "react-router-dom";

export default function PrivacyPolicy() {
    const { id } = useParams();

    const tel = id || "87475315558";

    const [phone, setPhone] = useState("");
    const [mail, setMail] = useState("");

    useEffect(() => {
        const formData = new FormData();
        formData.append("phone", tel);

        api.post("/getFranchiseeByPhone", formData, {
            headers: { "Content-Type": "application/json" },
        })
            .then(({ data }) => {
                setPhone(
                    `+7 ${data.phone.substring(1, 4)} ${data.phone.substring(
                        4,
                        7
                    )} ${data.phone.substring(7, 9)} ${data.phone.substring(9)}`
                );
                setMail(data.mail);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [tel]);

    const footerRef = useRef(null);

    
    const privacyPolicy = `
\nНастоящая политика конфиденциальности действует в отношении всей информации, которую клиентское приложение «Вода «Тибетская»», устанавливаемое на мобильные устройства, может получить от Пользователя во время его использования.
\n1. Термины
\n1.1. «Приложение» — клиентское приложение «Вода «Тибетская»» для заказа бутилированной воды, устанавливаемое на мобильные устройства Пользователя.
\n1.2. «Администрация» — лица, уполномоченные ТОО «Partner’s Mountain Water Co» на управление Приложением, которые организуют и/или осуществляют обработку персональных данных, а также определяют цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными.
\n1.3. «Персональные данные» — сведения, относящиеся к определенному или определяемому на их основании физическому лицу (субъекту персональных данных).
\n1.4. «Регистрационные данные» — иные, не относящиеся к понятию персональные данные сведения, предоставленные Приложению любыми лицами при регистрации в Приложении, а также при его использовании.
\n1.5. «Обработка регистрационных/персональных данных» — действия,
направленные на накопление, хранение, изменение, дополнение,
использование, распространение, обезличивание, блокирование и уничтожение
регистрационных/персональных данных.
\n1.6. «Пользователь» — лицо, использующее Приложение для заказа
бутилированной воды «Тибетская».
\n1.7. «Cookies» — небольшие фрагменты данных, сохраняемые на устройстве
Пользователя для улучшения работы Приложения.
\n1.8. «IP-адрес» — уникальный сетевой адрес устройства в компьютерной сети,
через который Пользователь получает доступ к Приложению.
\n1.9. «Товар» — бутилированная вода «Тибетская», которую Пользователь
заказывает через Приложение.
\n2. Общие положения
\n2.1. Использование Приложения Пользователем означает согласие с настоящей
Политикой конфиденциальности и условиями обработки
регистрационных/персональных данных.
\n2.2. В случае несогласия с условиями Политики конфиденциальности Пользователь
должен прекратить использование Приложения.
\n2.3. Настоящая Политика конфиденциальности применяется исключительно к
Приложению «Вода «Тибетская»». Администрация не несет ответственности за
сайты третьих лиц, на которые Пользователь может перейти по ссылкам из
Приложения.
\n3. Предмет Политики конфиденциальности
\n3.1. Политика устанавливает обязательства Администрации по неразглашению и
обеспечению защиты регистрационных/персональных данных, которые
Пользователь предоставляет при регистрации или использовании Приложения.
\n3.2. Регистрационные/персональные данные включают в себя:
\n● ФИО;
\n● Номер телефона;
\n● E-mail;
\n● Адрес доставки;
\n● Иные данные, необходимые для оформления и доставки заказа.
\n3.3. Приложение автоматически собирает данные:
\n● IP-адрес устройства;
\n● Информацию из cookies;
\n● Характеристики браузера и операционной системы.
\n4. Цели сбора персональной информации Пользователя
\n4.1. Администрация использует данные для:
\n● Идентификации Пользователя;
\n● Предоставления персонализированного доступа к Приложению;
\n● Обратной связи с Пользователем;
\n● Подтверждения достоверности данных;
\n● Обработки и доставки заказов;
\n● Улучшения качества обслуживания;
\n● Информационных рассылок при согласии Пользователя.
\n5. Способы и сроки обработки персональных данных
\n5.1. Обработка данных осуществляется без ограничения срока любыми законными
способами.
\n5.2. Пользователь дает согласие на передачу данных аффилированным компаниям,
курьерским службам и другим третьим лицам для выполнения заказа.
\n5.3. Данные могут быть переданы государственным органам в порядке,
установленном законодательством Республики Казахстан.
\n5.4. Администрация принимает меры для защиты данных от несанкционированного
доступа и разглашения.
\n6. Права и обязанности сторон
\n6.1. Пользователь имеет право:
\n● Самостоятельно принимать решение о предоставлении данных;
\n● Обновлять и дополнять данные;
\n● Требовать уточнения, блокирования или уничтожения данных;
\n● Получать информацию об обработке своих данных.
\n6.2. Администрация обязуется:
\n● Использовать данные только для указанных целей;
\n● Обеспечивать конфиденциальность данных;
\n● Принимать меры по защите данных;
\n● Блокировать данные по запросу Пользователя или уполномоченного органа.
\n7. Ответственность сторон
\n7.1. Администрация не несет ответственности в случае утраты или разглашения
данных, если они:
\n● Стали публичным достоянием до утраты или разглашения;
\n● Были получены от третьей стороны;
\n● Были разглашены с согласия Пользователя.
\n7.2. Пользователь несет ответственность за соблюдение законодательства
Республики Казахстан.
\n7.3. Администрация не несет ответственности за действия Пользователя,
нарушающие права третьих лиц.
\n8. Разрешение споров
\n8.1. Перед обращением в суд необходимо предъявить письменную претензию.
\n8.2. Получатель претензии обязан в течение 30 дней сообщить о результатах ее
рассмотрения.
\n8.3. Споры рассматриваются в судебных органах по месту нахождения ТОО
«Partner’s Mountain Water Co».
\n8.4. К Политике применяется законодательство Республики Казахстан.
\n9. Дополнительные условия
\n9.1. Администрация вправе вносить изменения в Политику без согласия
Пользователя.
\n9.2. Новая редакция Политики вступает в силу с момента ее размещения в
Приложении.
\n9.3. Вопросы по Политике следует направлять по адресу: info@tibetskaya.kz.
\n9.4. Действующая Политика доступна в Приложении в разделе «Регистрация» и по
адресу: https://tibetskaya.kz/privacyPolicy
    `

    return (
        <>
            <div className="pt-5 px-5 space-y-3">
                <h2 className="text-lg font-bold">ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ</h2>
                <div>
                    <p>{privacyPolicy}</p>
                </div>
            </div>

            <div className="bg-[#A7D1EC]">
                <Footer ref={footerRef} phone={phone} mail={mail} />
            </div>
        </>
    );
}
